@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        scroll-behavior: smooth;
    }

    body {
        @apply font-poppins
    }

    p {
        @apply text-gray-600
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-roboto
    }
}

@layer components {
    .section_wrapper {
        @apply bg-slate-50 dark:bg-slate-900 rounded-2xl 2xl:p-10 lg:p-8 p-6 space-y-12
    }

    .container{
        max-width: 1280px;
    }
}

.nav-link.active {
    @apply !text-primary-500
}


/* Vue transition fade */
.fade-enter-active,
.fade-leave-active {
    @apply transition-all duration-300
}

.fade-enter-from {
    @apply opacity-0 -translate-x-4
}

.fade-leave-to {
    @apply opacity-0 translate-x-4
}